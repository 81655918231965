export const getPagerHeaders = (response) => {
  let page = 0
  let limit = response.body.total
  if (response.body.query._page) {
    page = parseInt(response.body.query._page)
  }
  
  if (response.body.query._limit) {
    limit = response.body.query._limit
  }
  
  const urlParts = response.xhr.responseURL.split('?')
  const url = urlParts[0]
  const totalPages = Math.ceil(response.body.total / limit)
  
  const links = {}
  if (page !== 0) {
    const query = new URLSearchParams(urlParts[1])
    query.set('_page', 0)
    links.first = `${url}?${query.toString()}`
  }

  if (page > 0) {
    const query = new URLSearchParams(urlParts[1])
    query.set('_page', page - 1)
    links.prev = `${url}?${query.toString()}`
  }

  if (page < totalPages - 1) {
    const query = new URLSearchParams(urlParts[1])
    query.set('_page', page + 1)
    links.next = `${url}?${query.toString()}`
  }

  if (page !== totalPages - 1) {
    const query = new URLSearchParams(urlParts[1])
    query.set('_page', totalPages - 1)
    links.last = `${url}?${query.toString()}`
  }
  
  const end = limit > response.body.data.length 
    ? ((page) * limit) + response.body.data.length
    : ((page + 1) * limit)

  return {
    links,
    count: response.body.total,
    start: ((page) * limit) + 1,
    end: end,
  }
}
