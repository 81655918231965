import superagent from 'superagent'

export const Get = (url, query) => {
  return superagent
    .get(`${process.env.API_URL}${url}${query ? `?${query.toString()}` : ''}`)
    .withCredentials()
    .accept('*')
}

export const Post = (url, data = {}) => {
  return superagent
    .post(`${process.env.API_URL}${url}`)
    .withCredentials()
    .accept('*')
    .send(data)
}

export const Put = (url, data = {}) => {
  return superagent
    .put(`${process.env.API_URL}${url}`)
    .withCredentials()
    .accept('*')
    .send(data)
}
