import Button from 'react-material-site/lib/components/Button'
import React from 'react'

class Modal extends React.Component {
  logoutUser = (ans) => {
    if (ans) {
      let userId = this.props.data.id
      this.props.logoutUser(userId)
    }
    this.props.submit()
  }

  render() {
    return (
      <div style={{padding: '20px', minHeight: '125px'}}>
        <div>You are logging out the user from the Gazelle Platform.</div>
        <div style={{fontWeight: 'bold'}}>Are you sure ?</div>
        <div style={{position: 'absolute', right: '15px'}}>
          <Button text='Yes' onClick={() => this.logoutUser(true)} style={{color: 'white', background: '#8e1971', margin: '10px'}}/>
          <Button text='No' onClick={() => this.logoutUser(false)} style={{color: 'white', background: '#8e1971', margin: '10px'}}/>
        </div>
      </div>
    )
  }
}

export default Modal