

export const binarySearch = (userChannels, channel, start, end) => { 
  if (userChannels.length === 0) return false
  if (start > end) return false
  let mid = Math.floor((start + end)/2)
  if (userChannels[mid] === channel) return true
  if(userChannels[mid] > channel) {  
    return binarySearch(userChannels, channel, start, mid-1); 
  }
  else {
    return binarySearch(userChannels, channel, mid+1, end); 
  }
}