import React from 'react'
import moment from 'moment'
import Form from '../../components/Form'

export class User extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accountEnable: false
    }
  }
  componentDidMount() {
    let userID = this.props.data.id
    this.props.userType(userID)
    this.setState({
      accountEnable: this.props.accountEnable
    })
  }
  componentWillReceiveProps(nextProps) {
    if ((nextProps.data.id !== this.props.data.id) || (nextProps.accountEnable !== this.props.accountEnable)) {
      let userID = nextProps.data.id
      this.props.userType(userID)
      this.setState({
        accountEnable: nextProps.accountEnable
      })
    }
  }
  
  accountChange = (option) => {
    this.setState({
      accountEnable: !option
    })
    this.props.accountChange(this.props.data.id, !option)
  }
  render() {
    let props = this.props
  return (
  <Form 
    style={{background: 'none'}}
    
    //{...props.form}
    //method={props.data && props.data._id && props.form ? props.form['method'] : 'POST'}
    //action={props.data && props.data._id ? `${process.env.API_URL}/users/${props.data._id}` : `${process.env.API_URL}/organizations`}
    data={props.data}
    onSubmit={props.formSubmit}
    onChange={props.formChange}
    submitLabel='Save'
    removeUserLabel='Remove User'
    changeAccountType={this.accountChange}
    userType={props.userType}
    fields={{
      email: {
        type: 'text',
        label: 'Email',
        required: true,
        //pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
        error: 'Email must be properly formatted'
      },
      status: {
        type: 'radio',
        options: { active: 'Active', inactive: 'In-Active', blocked: 'Blocked' }
      },
      groups: {
        type: 'select',
        parseValue: (value) => value.length > 0 ? value[0] : '',
        options: { 
          admin: 'Admin',
          user: 'User',
          demo: 'Basic Demo',
          demo_dl: 'Advanced Demo'
        }
      },
      accountShare: {
        type: 'account-share',
        options: { 
          labelText: 'Enable Account Sharing',
          value: this.state.accountEnable
        }
      },
      expires_on: {
        type: 'date',
        label: 'Expires On',
        parseValue: (value) => value ? moment(value).format('YYYY-MM-DD') : '',
        error: 'Expiration date must be set'
      },
      created_on: {
        type: 'display',
        label: 'Created On',
      },
      password: {
        type: 'text',
        label: 'Password',
        error: 'Password must be at least 2 characters long'
      },
      first_name: {
        type: 'text',
        label: 'First Name',
        pattern: '[a-zA-Z .-,]{2,}$',
        error: 'A first name must be only letters and longer than 2'
      },
      last_name: {
        type: 'text',
        label: 'Last Name',
        pattern: '[a-zA-Z .-,]{2,}$',
        error: 'A last name must be only letters and longer than 2'
      },
      company: {
        type: 'text',
        label: 'Company Name',
        pattern: '[a-zA-Z .-&,]{0,}$',
        error: 'A company name must be only letters and longer than 2'
      },
      username: {
        type: 'display',
        label: 'Username (Legacy)',
      },
    }}
  />
)
  }
}
export default User
