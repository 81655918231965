export const mergeQuery = (query, defaultQuery) => {
  Object.keys(defaultQuery).forEach((item) => {
    if (!query.has(item)) {
      query.set(item, defaultQuery[item])
    }
  })

  return query
}

export const translateQuery = (query, fields, translateName = (name) => name, translateValue = (value) => value) => {
  fields.forEach((field) => {
    if (query.has(field)) {
      query.set(translateName(field), translateValue(query.get(field)))
      query.delete(field)
    }
  })

  return query
}
