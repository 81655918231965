import { replace } from 'connected-react-router'
import { Get, Post } from '../utils/xhr'
import { globalMessage } from 'react-material-site/lib/modules/ui'

// ------------------------------------
// Constants
// ------------------------------------
export const AUTH_UPDATE = 'AUTH_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const authUpdate = (params) => {
  return {
    type    : AUTH_UPDATE,
    payload : params
  }
}

export const fetchProfile = () => {
  return async (dispatch) => {
    Get('/users/self').then((response) => {
      dispatch(authUpdate({ profile: response.body }))
    }).catch(() => {
      clearSession()
      dispatch(replace({ pathname: '/login' }))
    })
  }
}

export const loginSubmit = () => {
  return async (dispatch, getState) => {
    const state = getState()
    Post('/login', state.auth.form).then(async () => {      
      const date = new Date()
      localStorage.setItem('expires_at', date.setDate(date.getDate() + 31))
      await dispatch(fetchProfile())
      dispatch(replace({ pathname: '/users' }))
    }).catch((error) => {
      console.error(error)
      clearSession()
      dispatch(globalMessage('There was an error when trying to log you in'))
    })
  }
}

export const loginChange = (event) => {
  return async (dispatch, getState) => {
    const form = Object.assign(
      {},
      getState().auth.form,
      { [event.target.name]: event.target.value }
    )
    
    dispatch(authUpdate({ form }))
  }
}

export const logout = () => {
  return async (dispatch) => {
    Post('/logout', {}).then(() => {
      clearSession()
      dispatch(replace({ pathname: '/login' }))
      dispatch(authUpdate({ profile: {} }))
    }).catch((error) => {
      console.error(error)
      dispatch(globalMessage('There was an error when trying to log you out'))
    })
  }
}

export const authenticated = () => {
  let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
  return new Date().getTime() < expiresAt
}

const clearSession = () => {
  localStorage.removeItem('expires_at')
}

export const actions = {
  loginSubmit,
  loginChange
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [AUTH_UPDATE] : (state, action) => Object.assign({}, state, action.payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isAuthenticated: authenticated,
  profile: {},
  form: {
    login: '',
    password: ''
  }
}

export default function authReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
