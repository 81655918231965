import React, { Component } from 'react'
import { connect } from 'react-redux'
import CoreLayout from 'react-material-site/lib/layouts/CoreLayout'

import { logout } from '../../modules/auth'

class Logout extends Component {
  componentWillMount() {
    this.props.logout()
  }

  componentWillReceiveProps() {
    this.props.logout()
  }

  render() {
    const props = this.props
    return (
      <CoreLayout {...props}>
      </CoreLayout>
    )
  }
}

const mapDispatchToProps = {
  logout
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
